import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars, faTimes, faPhone, faEnvelope, faUsers, faBook, faUser, faQuestion,
  faList, faClipboard,
} from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

library.add(
  faBars,
  faTimes,
  faPhone,
  faEnvelope,
  faUsers,
  faUser,
  faBook,
  faQuestion,
  faList,
  faClipboard,
  faFacebook,
  faInstagram,
);
