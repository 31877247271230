import React from 'react';
import { useTranslation } from 'react-i18next';
import Spacer from '../components/Spacer';

function Donate() {
  const { t } = useTranslation();

  return (
    <>
      <Spacer />
      <div className="container" style={{ minHeight: 1000 }}>
        <h1 className="display-4 text-center text-primary">
          {t('donate.title')}
        </h1>
        <div>
          <p>{t('donate.paragraph1')}</p>
          <p>
            {t('donate.paragraph2')}
            <br />
            <b>{t('donate.paragraph3')}</b>
            <br />
            <b>{t('donate.paragraph4')}</b>
            <br />
            <b>{t('donate.paragraph5')}</b>
          </p>
          <p>
            {t('donate.paragraph6')}
          </p>

          <p>
            {t('donate.paragraph7')}
          </p>

          <p>
            {t('donate.paragraph8')}
            <a href="https://www.venmo.com/squadclub630" target="_blank" rel="noopener noreferrer">
              <img
                className="align-self-center z-depth-2"
                alt="logo"
                width={20}
                src="./assets/venmo.png"
                data-holder-rendered="true"
              />
            </a>
          </p>

          <p>
            {t('donate.paragraph9')}
          </p>

          <p>
            {t('donate.paragraph10')}
          </p>
        </div>
      </div>
    </>
  );
}

export default Donate;
