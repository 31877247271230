import React from 'react';
import { useTranslation } from 'react-i18next';
import Spacer from '../components/Spacer';
import Profile from '../components/Profile';

function AboutMe() {
  const { t } = useTranslation();

  return (
    <>
      <Spacer />
      <div className="container" style={{ minHeight: 1000 }}>
        <h1 className="display-4 text-center text-primary">{t('aboutMe.title')}</h1>
        <div className="row">
          <div className="col-12">
            <Profile
              header={t('aboutMe.profileHeader')}
              name={t('aboutMe.profileName')}
              image={t('aboutMe.profileImage')}
              schools={t('aboutMe.profileSchools')}
              desc={t('aboutMe.profileDesc')}
            />
          </div>
        </div>
        <p className="text-primary">{t('aboutMe.p1')}</p>
        <p className="text-primary">{t('aboutMe.p2')}</p>
        <p className="text-primary">{t('aboutMe.p3')}</p>
        <p className="text-primary">{t('aboutMe.p4')}</p>
        <p className="text-primary">{t('aboutMe.p5')}</p>
        <p className="mt-5 text-primary">{t('aboutMe.signature')}</p>
      </div>
    </>
  );
}

export default AboutMe;
