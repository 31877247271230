/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import ReactGA from 'react-ga';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import Navbar from '../Navbar';

import { NAVBAR_SCHEMA } from '../../schemas/navbar_schema';
import useWindowSize from '../../hooks/useWindowSize';
import useScrollPosition from '../../hooks/useScrollPosition';

const WIDTH_BREAKPOINT = 768;
const SCROLL_BREAKPOINT = 10;

function MainNavbar() {
  const selected = localStorage.getItem('i18nextLng') || 'en';
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [width] = useWindowSize();
  const [scroll] = useScrollPosition();
  const [nav, setNav] = useState(scroll < SCROLL_BREAKPOINT);
  const [mobile, setMobile] = useState(width < WIDTH_BREAKPOINT);

  useEffect(() => {
    if (open) {
      setNav(false);
    } else {
      setNav(scroll < SCROLL_BREAKPOINT);
    }
    setMobile(width < WIDTH_BREAKPOINT);
  }, [width, scroll]);

  const renderMenuIcon = () => {
    const setNavColor = () => {
      if (!open) {
        setNav(false);
      } else if (scroll < SCROLL_BREAKPOINT) {
        setNav(true);
      }
      setOpen(!open);
    };
    return (
      <button type="button" className="d-md-none btn btn-secondary m-2" onClick={setNavColor}>
        {open
          ? <FontAwesomeIcon className="text-white" icon={['fa', 'times']} />
          : <FontAwesomeIcon className="text-white" icon={['fa', 'bars']} />}
      </button>
    );
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const setLanguage = () => {
    const changeTo = selected === 'en' ? 'Spanish' : 'English';
    ReactGA.event({
      category: 'Change Laguage',
      action: changeTo,
    });
    closeMenu();
    i18next.changeLanguage(selected === 'en' ? 'sp' : 'en');
  };

  return (
    <Navbar classNames={`${!nav ? 'bg-light-gray-trans blur' : 'bg-transparent'}`}>
      <Navbar.Main>
        <Navbar.HomeItem
          text={t(NAVBAR_SCHEMA.home.text)}
          href={NAVBAR_SCHEMA.home.href}
          classNames="h1 d-block text-secondary m-1 nounderline me-auto"
          onClick={closeMenu}
        />
        { _.map(NAVBAR_SCHEMA.items, (item) => (
          !mobile && (
          <Navbar.MenuItem
            key={item.text}
            text={t(`navbar.${item.text}`)}
            href={item.href}
            classNames="h5 text-primary d-block p-2 nounderline"
            onClick={closeMenu}
          />
          )
        ))}
        <Navbar.MenuItem
          text={t(`navbar.${NAVBAR_SCHEMA.language.text}`)}
          classNames="h5 text-warning p-2 nounderline pointer-hand"
          onClick={setLanguage}
        />
        { renderMenuIcon() }
      </Navbar.Main>
      { mobile && open
        && (
        <Navbar.Mobile>
          {_.map(NAVBAR_SCHEMA.items, (item) => (
            mobile && open && (
            <Navbar.MobileItem
              key={item.text}
              text={t(`navbar.${item.text}`)}
              href={item.href}
              classNames="d-block h5 m-0 p-2 border-bottom border-primary nounderline text-primary"
              onClick={closeMenu}
            />
            )
          ))}
        </Navbar.Mobile>
        )}
    </Navbar>
  );
}
export default MainNavbar;
