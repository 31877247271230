export const NAVBAR_SCHEMA = {
  home: {
    text: 'Desert Patrolmen Squad Club',
    href: '/home',
  },
  items: [
    // {
    //   dropdown: false,
    //   isActive: false,
    //   text: 'aboutMe',
    //   icon: 'user',
    //   href: '/about-me',
    // },
    {
      dropdown: false,
      isActive: false,
      text: 'donate',
      icon: 'clipboard',
      href: '/donate',
    },
    {
      dropdown: false,
      isActive: false,
      text: 'contactUs',
      icon: 'envelope',
      href: '/contact-us',
    },
    // {
    //   dropdown: false,
    //   isActive: false,
    //   text: 'resources',
    //   icon: 'list',
    //   href: '/resources',
    // },
    // {
    //   dropdown: false,
    //   isActive: false,
    //   text: 'faq',
    //   icon: 'question',
    //   href: '/faq',
    // },
  ],
  language: {
    text: 'language',
    href: '/language',
  },
};

export default {
  NAVBAR_SCHEMA,
};
