import React from 'react';
import { useTranslation } from 'react-i18next';
import Spacer from '../components/Spacer';
import Card from '../components/Card';

function Services() {
  const { t } = useTranslation();

  return (
    <>
      <Spacer />
      <div className="container" style={{ minHeight: 1000 }}>
        <h1 className="display-4 text-center text-primary">{t('services.title')}</h1>
        <h4 className="text-primary">{t('services.paragraph')}</h4>
        <p className="text-primary">{t('services.paragraph-2')}</p>
        <h4 className="text-primary">{t('services.tele-header')}</h4>
        <p className="text-primary">{t('services.tele-paragraph')}</p>
        <div className="row p-2">
          <div className="col-md-4 p-1">
            <Card
              classNames="h-100"
              header={t('services.service-header-individual')}
              subHeader={t('services.service-price-individual')}
              desc={t('services.service-paragraph-individual')}
              buttonDesc={t('services.schedule')}
            />
          </div>
          <div className="col-md-4 p-1">
            <Card
              classNames="h-100"
              header={t('services.service-header-couples')}
              subHeader={t('services.service-price-couples')}
              desc={t('services.service-paragraph-couples')}
              buttonDesc={t('services.schedule')}
            />
          </div>
          <div className="col-md-4 p-1">
            <Card
              classNames="h-100"
              header={t('services.service-header-adolescent')}
              subHeader={t('services.service-price-adolescent')}
              desc={t('services.service-paragraph-adolescent')}
              buttonDesc={t('services.schedule')}
            />
          </div>
        </div>
        <h4 className="text-primary">{t('services.reduced-header')}</h4>
        <p className="text-primary">{t('services.reduced-paragraph')}</p>
        <h4 className="text-primary">{t('services.insurances-header')}</h4>
        <p className="text-primary">{t('services.insurances-paragraph')}</p>
      </div>
    </>
  );
}

export default Services;
