/* eslint-disable no-alert */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Fade from 'react-reveal/Fade';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Hero from '../components/Hero';
import Spacer from '../components/Spacer';

function Home() {
  const { t } = useTranslation();
  return (
    <>
      <div className="container-xxl p-0">
        <Spacer height={70} />
        <p className="bg-danger p-2 m-0 text-white">
          {t('home.banner')}
        </p>
        <Hero height="50vh" image="./assets/background-chp.jpeg" />
        <Fade>
          <div className="row p-0 m-0">
            <div className="col-md-3 d-flex justify-content-around flex-column">
              <img
                className="align-self-center z-depth-2"
                alt="logo"
                width={250}
                src="./assets/LOGO.png"
                data-holder-rendered="true"
              />
            </div>
            <div className="col-md-9 d-flex align-items-center">
              <div className="p-4">
                <h1 className="text-primary">{t('home.welcome')}</h1>
                <p className="text-primary">{t('home.main')}</p>
                <p className="text-primary">{t('home.main2')}</p>
                <p className="text-primary">{t('home.main3')}</p>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="text-center mb-2 p-3"><Link to="donate" className="text-white btn btn-warning">{t('donateButton')}</Link></div>
            </div>
          </div>
        </Fade>
      </div>
    </>
  );
}

export default Home;
