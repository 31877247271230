import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Spacer from '../components/Spacer';

function Contact() {
  const { t } = useTranslation();
  const iframeRef = useRef(null);

  const handleIframeLoad = () => {
    // Access the iframe and remove the background style
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.style.background = 'none';
      console.log('Background style removed from the iframe!');
    }
  };

  return (
    <>
      <Spacer />
      <div className="container" style={{ minHeight: 1000 }}>
        <h1 className="display-4 text-center text-primary">{t('contactUs.title')}</h1>
        <div className="row">
          <div className="col-md-3">
            <p>
              {t('contactUs.message')}
            </p>
            <p>
              {t('contactUs.emailLabel')}
              {' '}
              <a href="mailto: desertpatrolmen@gmail.com">desertpatrolmen@gmail.com</a>
            </p>
          </div>
          <div className="col-md-9">
            <iframe
              ref={iframeRef}
              src="https://docs.google.com/forms/d/e/1FAIpQLSdPuwnDE0D9oyGxf7i_ZUM8JDsyMaPS0Y43MxZM1JHRyw9uQQ/viewform?embedded=true"
              title="Donation Form"
              width="100%"
              height="1200"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              onLoad={handleIframeLoad}
              style={{ background: 'url(./assets/loading.png) center top no-repeat', backgroundSize: '100px 100px' }}

            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
