/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import React from 'react';

import { useTranslation } from 'react-i18next';
import Footer from '../Footer';
import { NAVBAR_SCHEMA } from '../../schemas/navbar_schema';

function MainFooter() {
  const { t } = useTranslation();

  return (
    <Footer
      title={NAVBAR_SCHEMA.home.text}
      desc={t('subTitle')}
      links={NAVBAR_SCHEMA.items}
    />
  );
}

export default MainFooter;
